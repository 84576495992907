<template>
  <div class="contents">
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.opened"
        prop="opened"
        name="opened"
        label="Start Opened?"
        type="checkbox"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
